<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h1>Users List</h1>
        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/" class="active" target="_self">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/User" class="active" target="_self">User</a>
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location">Users list</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            @click="goToAdd"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Add New
          </button>
        </div>
        <div class="separator mb-5"></div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Username</th>
              <th>Email</th>
              <th>Provider</th>
              <th>Confirmed</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>{{ user.username }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.provider }}</td>
              <td>{{ user.confirmed ? "Oui" : "Non" }}</td>
              <td>{{ user.role ? user.role.name : "N/A" }}</td>
              <td>
                <button
                  @click="goToUpdateUser(user.id)"
                  class="btn btn-outline-info"
                >
                  Update
                </button>
                <button
                  @click="deleteUser(user.id)"
                  class="btn btn-outline-danger"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <button
                class="page-link"
                @click="prevPage"
                :disabled="currentPage === 1"
              >
                Previous
              </button>
            </li>
            <li
              v-for="pageNumber in totalPages"
              :key="pageNumber"
              class="page-item"
              :class="{ active: pageNumber === currentPage }"
            >
              <button class="page-link" @click="goToPage(pageNumber)">
                {{ pageNumber }}
              </button>
            </li>
            <li
              class="page-item"
              :class="{ disabled: currentPage === totalPages }"
            >
              <button
                class="page-link"
                @click="nextPage"
                :disabled="currentPage === totalPages"
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
import strapi from "@/services/strapi"; // Ensure this points to your Strapi service

export default {
  name: "UserList",
  data() {
    return {
      users: [],
      totalUsers: 0,
      totalPages: 0,
      usersPerPage: 6, // Change this value as needed
      currentPage: 1,
    };
  },
  async created() {
    await this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await strapi.get("/users?populate=*");
        this.users = response.data; // Assuming response.data contains the user list

        // Calculate total number of users and pages
        this.totalUsers = this.users.length;
        this.totalPages = Math.ceil(this.totalUsers / this.usersPerPage);

        // Slice users to show only current page's data
        const startIndex = (this.currentPage - 1) * this.usersPerPage;
        const endIndex = startIndex + this.usersPerPage;
        this.users = this.users.slice(startIndex, endIndex);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchUsers();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchUsers();
      }
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchUsers();
    },
    goToUpdateUser(userId) {
      this.$router.push({ name: "UserUpdate", params: { id: userId } });
    },
    goToAdd() {
      this.$router.push({ name: "UserCreate" }); // Replace 'CreateProduct' with your actual route name
    },
    async deleteUser(userId) {
      if (confirm("Are you sure you want to delete this user?")) {
        try {
          await strapi.delete(`/users/${userId}`);
          alert("User successfully deleted.");
          this.fetchUsers(); // Refresh the user list after deletion
        } catch (error) {
          console.error("Error deleting user:", error);
        }
      }
    },
  },
};
</script>
<style scoped>
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-item {
  margin: 0 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}
</style>
