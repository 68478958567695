<template>
  <div class="container-fuild">
    <div class="row">
      <div class="disable-text-selection col">
        <div class="row">
          <div class="col col-12">
            <h1>Product List</h1>
            <ul
              class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/" class="active" target="_self">Home</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="/product" class="active" target="_self">Product</a>
                </li>
                <li class="breadcrumb-item active">
                  <span aria-current="location">Product List</span>
                </li>
              </ol>
            </ul>
            <div class="top-right-button-container">
              <button
                @click="goToAddProduct"
                type="button"
                class="btn top-right-button btn-primary btn-lg"
              >
                Add New
              </button>
            </div>

            <div class="separator mb-5"></div>
          </div>
        </div>
        <div>
          <div class="row">
            <div
              v-for="product in products"
              :key="product.id"
              class="mb-3 col col-12"
            >
              <div class="card d-flex flex-row active">
                <a
                  :href="`/product/detail/${product.id}`"
                  class="d-flex fixed-size-image"
                >
                  <img
                    :src="getFirstImage(product)"
                    alt="Smart Fitness Tracker Watch"
                    class="list-thumbnail responsive border-0"
                  />
                </a>

                <div class="pl-2 d-flex flex-grow-1 min-width-zero">
                  <div
                    class="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center"
                  >
                    <a
                      :href="`/product/detail/${product.id}`"
                      class="w-40 w-sm-100"
                    >
                      <p class="list-item-heading mb-0 truncate">
                        {{ product.attributes.name }}
                      </p>
                    </a>
                    <p class="mb-0 text-muted text-small w-15 w-sm-100">
                      Price {{ product.attributes.price }}
                    </p>
                    <p class="mb-0 text-muted text-small w-15 w-sm-100">
                      {{ formatDate(product.attributes.createdAt) }}
                    </p>

                    <div
                      class="btn-group"
                      role="group"
                      aria-label="Product Actions"
                    >
                      <button
                        type="button"
                        class="btn btn-outline-info"
                        @click="goToUpdateProduct(product.id)"
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-danger"
                        @click="deleteProduct(product.id)"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === 1 }"
                  >
                    <button
                      class="page-link"
                      @click="prevPage"
                      :disabled="currentPage === 1"
                    >
                      Previous
                    </button>
                  </li>
                  <li
                    class="page-item"
                    v-for="pageNumber in totalPages"
                    :key="pageNumber"
                    :class="{ active: pageNumber === currentPage }"
                  >
                    <button class="page-link" @click="goToPage(pageNumber)">
                      {{ pageNumber }}
                    </button>
                  </li>
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === totalPages }"
                  >
                    <button
                      class="page-link"
                      @click="nextPage"
                      :disabled="currentPage === totalPages"
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";

export default {
  name: "ProductList",
  data() {
    return {
      products: [],
      totalRoutes: 0,
      totalPages: 0,
      routesPerPage: 6, // Change this value as needed
      currentPage: 1,
    };
  },
  async created() {
    await this.fetchProducts();
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return "N/A";
      const date = new Date(dateString);
      if (isNaN(date)) return "Invalid Date";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      return date.toLocaleDateString(undefined, options);
    },
    getFirstImage(product) {
      const baseURL = "http://localhost:1337"; // Ensure this points to your Strapi server
      const images = product.attributes.images.data;

      if (images && images.length > 0) {
        const firstImage = images[0].attributes;
        if (
          firstImage.formats &&
          firstImage.formats.thumbnail &&
          firstImage.formats.thumbnail.url
        ) {
          return `${baseURL}${firstImage.formats.thumbnail.url}`;
        } else if (firstImage.url) {
          return `${baseURL}${firstImage.url}`;
        }
      }
      return "/default-image.jpg"; // Provide a path to a default image
    },
    async fetchProducts() {
      try {
        const response = await strapi.get("/products?populate=*");
        this.products = response.data.data; // Update products with fetched data

        // Calculate total number of routes and pages
        this.totalRoutes = this.products.length;
        this.totalPages = Math.ceil(this.totalRoutes / this.routesPerPage);

        // Slice products to show only current page's data
        const startIndex = (this.currentPage - 1) * this.routesPerPage;
        const endIndex = startIndex + this.routesPerPage;
        this.products = this.products.slice(startIndex, endIndex);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchProducts();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchProducts();
      }
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchProducts();
    },
    goToAddProduct() {
      // Use router.push to navigate programmatically
      this.$router.push({ name: "CreateProduct" }); // Replace 'CreateProduct' with your actual route name
    },
    goToUpdateProduct(productId) {
      this.$router.push({ name: "UpdateProduct", params: { id: productId } });
    },
    async deleteProduct(productId) {
      if (confirm("Are you sure you want to delete this product?")) {
        try {
          await strapi.delete(`/products/${productId}`);
          alert("Product removed successfully.");
          this.fetchProducts(); // Refresh the product list after deletion
        } catch (error) {
          console.error("Error deleting product:", error);
        }
      }
    },
  },
};
</script>

<style scoped>
.list-thumbnail {
  width: 100px; /* Adjust the width as needed */
  height: 94px; /* Adjust the height as needed */
  object-fit: cover; /* Ensures the image covers the entire container */
  object-position: center;
}
.fixed-size-image {
  width: 100px; /* Set your desired width */
  height: 94px; /* Set your desired height */
  overflow: hidden;
}
</style>
