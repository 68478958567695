<template>
  <div v-if="product" class="container-fluid">
    <div class="col col-12">
      <h1>{{ getProductName }}</h1>
      <span>
        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/app" class="active" target="_self">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/Product" class="active" target="_self">Product</a>
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location"> Product Details</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            type="button"
            class="btn top-right-button mb-1 btn-outline-danger mr-2"
            @click="deleteProduct(product.id)"
          >
            Delete
          </button>
          <button
            type="button"
            class="btn top-right-button mb-1 btn-outline-info"
            @click="goToUpdateProduct(product.id)"
          >
            Update
          </button>
        </div>
      </span>
      <div class="separator mb-5"></div>
      <div class="row">
        <div class="col-left col-xl-8 col-12">
          <div class="card mb-4">
            <div class="card-body">
              <div>
                <div class="carousel">
                  <button @click="prevImage" class="carousel-control-prev">
                    <span
                      aria-hidden="true"
                      class="carousel-control-prev-icon"
                    ></span>
                    <span class="sr-only">Previous</span>
                  </button>
                  <div class="carousel-inner">
                    <div
                      v-for="(image, index) in getProductImages"
                      :key="index"
                      :class="[
                        'carousel-item',
                        { active: index === activeImageIndex },
                      ]"
                    >
                      <img
                        :src="
                          image.url.startsWith('/')
                            ? `http://localhost:1337${image.url}`
                            : image.url
                        "
                        alt="detail"
                        class="d-block w-100"
                      />
                    </div>
                  </div>
                  <button @click="nextImage" class="carousel-control-next">
                    <span
                      aria-hidden="true"
                      class="carousel-control-next-icon"
                    ></span>
                    <span class="sr-only">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-4">
            <div class="tabs">
              <div class="card-header">
                <ul
                  role="tablist"
                  class="nav nav-tabs card-header-tabs"
                  id="__BVID__2308__BV_tab_controls_"
                >
                  <li role="presentation" class="nav-item">
                    <a
                      role="tab"
                      aria-selected="true"
                      aria-setsize="3"
                      aria-posinset="1"
                      href="#"
                      target="_self"
                      class="nav-link active"
                      id="__BVID__2309___BV_tab_button__"
                      aria-controls="__BVID__2309"
                    >
                      Description
                    </a>
                  </li>
                </ul>
              </div>
              <div tab-content>
                <div class="tab-pane active card-body">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="card-text">
                        <p class="font-weight-bold">
                          {{ getProductDescription }}
                        </p>
                        <p class="font-weight-bold">
                          Price {{ getProductPrice }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-right col-xl-4 col-12">
          <div class="card mb-4">
            <div class="card-body">
              <h4 class="card-title">Category</h4>
              <div>
                <div class="row social-image-row gallery">
                  <div
                    v-for="category in productCategories"
                    :key="category.id"
                    class="col col-6"
                  >
                    <p
                      class="badge mb-1 mr-1 badge-outline-secondary badge-pill"
                    >
                      {{ category.attributes.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-body">
              <h4 class="card-title">Specifications</h4>
              <div>
                <div class="row social-image-row gallery">
                  <div class="col col-12">
                    <p
                      class="badge mb-1 mr-1 badge-outline-secondary badge-pill"
                    >
                      {{ getProductSpecification }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Chargement...</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ProductDetail",
  data() {
    return {
      product: null,
      activeImageIndex: 0,
    };
  },
  async created() {
    await this.fetchProduct();
  },
  methods: {
    async fetchProduct() {
      const productId = this.$route.params.id;
      try {
        const response = await axios.get(
          `http://localhost:1337/api/products/${productId}?populate=*`
        );
        this.product = response.data.data;
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    },
    goToUpdateProduct(productId) {
      this.$router.push({ name: "UpdateProduct", params: { id: productId } });
    },
    async deleteProduct(productId) {
      try {
        const response = await axios.delete(
          `http://localhost:1337/api/products/${productId}`
        );
        if (response.status === 200) {
          alert("Product removed successfully!");
          this.$router.push({ name: "ProductList" });
        } else {
          throw new Error("Failed to remove product.");
        }
      } catch (error) {
        console.error("Error deleting product:", error);
        alert("Failed to remove product. Try Again.");
      }
    },
    nextImage() {
      if (this.activeImageIndex < this.getProductImages.length - 1) {
        this.activeImageIndex++;
      }
    },
    prevImage() {
      if (this.activeImageIndex > 0) {
        this.activeImageIndex--;
      }
    },
  },
  computed: {
    getProductName() {
      return this.product && this.product.attributes
        ? this.product.attributes.name
        : "Product Name Unavailable";
    },
    getProductDescription() {
      return this.product && this.product.attributes
        ? this.product.attributes.description
        : "Product Description Unavailable";
    },
    getProductPrice() {
      return this.product && this.product.attributes
        ? this.product.attributes.price
        : "Product Price Unavailable";
    },
    getProductImages() {
      if (
        this.product &&
        this.product.attributes &&
        this.product.attributes.images &&
        this.product.attributes.images.data
      ) {
        return this.product.attributes.images.data.map((img) => ({
          id: img.id,
          url: img.attributes.url,
        }));
      }
      return [];
    },
    getProductSpecification() {
      return this.product && this.product.attributes
        ? this.product.attributes.specification
        : "Product Specification Unavailable";
    },
    productCategories() {
      return this.product &&
        this.product.attributes &&
        this.product.attributes.categories
        ? this.product.attributes.categories.data
        : [];
    },
  },
};
</script>

<style scoped>
.carousel {
  position: relative;
  overflow: hidden; /* Ensure images do not overflow */
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent; /* Make background transparent */
  border: none;
  padding: 0; /* Adjust padding as needed */
  cursor: pointer;
  z-index: 10; /* Ensure controls are above images */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  color: #000; /* Set color of arrows */
  font-size: 24px; /* Adjust size of arrows */
}

.carousel-control-prev {
  left: 0; /* Position to left */
}

.carousel-control-next {
  right: 0; /* Position to right */
}

.carousel-item {
  text-align: center; /* Center align images */
}

.carousel-item img {
  max-width: 100%; /* Ensure images don't exceed parent width */
  height: auto; /* Maintain aspect ratio */
  max-height: 400px; /* Set max height of images */
  margin: 0 auto; /* Center align images horizontally */
}
</style>
