<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-12">
        <h1>Update Brand</h1>
        <!-- Breadcrumb code omitted for brevity -->
        <div class="top-right-button-container">
          <button
            @click="goToBrandList"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Return to Brand List
          </button>
        </div>
        <div class="separator mb-5"></div>
      </div>
      <div class="col col-12">
        <div class="card mb-4">
          <div class="card-body">
            <h4 class="card-title">Update Brand</h4>
            <form @submit.prevent="updateBrand">
              <fieldset class="form-group">
                <label for="name" class="col-form-label">Brand Name</label>
                <input
                  v-model="brand.name"
                  type="text"
                  class="form-control"
                  id="name"
                  required
                />
              </fieldset>
              <fieldset class="form-group">
                <label for="link" class="col-form-label">Website Link</label>
                <input
                  v-model="brand.link"
                  type="text"
                  class="form-control"
                  id="link"
                  required
                />
              </fieldset>
              <fieldset class="form-group">
                <label for="logo" class="col-form-label">Logo</label>
                <input
                  type="file"
                  id="logo"
                  ref="logoInput"
                  accept="image/*"
                  @change="handleLogoChange"
                  class="form-control-file"
                />
                <div v-if="brand.logo">
                  <p>Current Logo:</p>
                  <img
                    :src="getImageUrl(brand.logo.url)"
                    alt="Current Logo"
                    class="image-preview"
                  />
                </div>
                <div v-if="newLogo">
                  <p>New Logo:</p>
                  <p>{{ newLogo.name }}</p>
                </div>
              </fieldset>
              <button type="submit" class="btn btn-primary">
                Update Brand
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";

export default {
  name: "UpdateBrand",
  data() {
    return {
      brand: {
        id: null,
        name: "",
        link: "",
        logo: null,
      },
      newLogo: null,
    };
  },
  async created() {
    await this.fetchBrandDetails();
  },
  methods: {
    getImageUrl(imagePath) {
      return `http://localhost:1337${imagePath}`;
    },
    async fetchBrandDetails() {
      const brandId = this.$route.params.id;
      if (!brandId) {
        console.error("Brand ID is undefined in route parameters.");
        return;
      }
      try {
        const response = await strapi.get(`/brands/${brandId}`);
        const attributes = response.data.data.attributes;
        this.brand = {
          id: brandId,
          name: attributes.name || "",
          link: attributes.link || "",
          logo: attributes.logo || null,
        };
        console.log("Fetched Brand:", this.brand);
      } catch (error) {
        console.error("Error fetching brand details:", error);
      }
    },
    handleLogoChange(event) {
      const file = event.target.files[0];
      this.newLogo = file;
    },
    async updateBrand() {
      if (!this.brand.id) {
        console.error("Brand ID is undefined.");
        return;
      }

      try {
        const formData = new FormData();
        formData.append(
          "data",
          JSON.stringify({
            name: this.brand.name,
            link: this.brand.link,
          })
        );

        if (this.newLogo) {
          formData.append("files.logo", this.newLogo);
        }

        const response = await strapi.put(
          `/brands/${this.brand.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Brand updated:", response.data);
        alert("Brand updated successfully!");
        this.$router.push({ name: "BrandPage" });
      } catch (error) {
        console.error("Error updating brand:", error);
      }
    },
    goToBrandList() {
      this.$router.push({ name: "BrandPage" });
    },
  },
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
}

div {
  margin-bottom: 1rem;
}

.image-preview {
  width: 100px;
  height: auto;
  margin-top: 10px;
}
</style>
