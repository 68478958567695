<template>
  <div v-if="category" class="container-fluid">
    <div class="col col-12">
      <h1>{{ getCategoryName }}</h1>
      <span>
        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/app" class="active" target="_self">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/Category" class="active" target="_self">Category</a>
            </li>

            <li class="breadcrumb-item active">
              <span aria-current="location">Details</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            type="button"
            class="btn top-right-button mb-1 btn-outline-danger mr-2"
            @click="deleteCategory(category.id)"
          >
            Delete
          </button>

          <button
            type="button"
            class="btn top-right-button mb-1 btn-outline-info"
            @click="goToUpdateCategory(category.id)"
          >
            Update
          </button>
        </div>
      </span>
      <div class="separator mb-5"></div>
      <div class="row">
        <div class="col-left col-xl-8 col-12">
          <div class="card mb-4">
            <div class="card-body">
              <div>
                <div
                  class="glide details glide--ltr glide--slider glide--swipeable"
                >
                  <div data-glide-el="track" class="glide__track">
                    <div class="glide__slides">
                      <div v-if="getCategoryImage" class="glide__slide">
                        <img
                          :src="getCategoryImage"
                          alt="detail"
                          class="responsive border-0 border-radius img-fluid mb-3"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-4">
            <div class="tabs">
              <div class="card-header">
                <ul
                  role="tablist"
                  class="nav nav-tabs card-header-tabs"
                  id="__BVID__2308__BV_tab_controls_"
                >
                  <!---->
                  <li role="presentation" class="nav-item">
                    <a
                      role="tab"
                      aria-selected="true"
                      aria-setsize="3"
                      aria-posinset="1"
                      href="#"
                      target="_self"
                      class="nav-link active"
                      id="__BVID__2309___BV_tab_button__"
                      aria-controls="__BVID__2309"
                      >Description</a
                    >
                  </li>

                  <!---->
                </ul>
              </div>
              <div tab-content>
                <div class="tab-pane active card-body">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="card-text">
                        <p class="font-weight-bold">
                          {{ getCategoryDescription }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-right col-xl-4 col-12">
          <div class="card mb-4">
            <div class="card-body">
              <!----><!---->
              <h4 class="card-title">Products</h4>
              <div>
                <div class="row social-image-row gallery">
                  <div
                    v-for="product in products"
                    :key="product.id"
                    class="col col-6"
                  >
                    <img
                      :src="getFirstImage(product)"
                      alt="Product Image"
                      class="responsive border-0 border-radius img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!----><!---->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Chargement...</p>
  </div>
</template>

<script>
import axios from "axios";
import strapi from "@/services/strapi";

export default {
  name: "CategoryDetail",
  data() {
    return {
      category: "",
      categories: [],
      products: [],
      product: {
        name: "",
        description: "",
        price: "",
        images: null,
        is_featured: false,
        category: "",
        specification: "",
      },
    };
  },
  async created() {
    await this.fetchCategory();
    await this.fetchProductsByCategory();
    const [categoriesRes] = await Promise.all([strapi.get("/categories")]);

    this.categories = categoriesRes.data.data;
  },
  mounted() {
    console.log("Products:", this.products);
  },
  methods: {
    async fetchCategory() {
      const categoryId = this.$route.params.id;
      try {
        const response = await axios.get(
          `http://localhost:1337/api/categories/${categoryId}?populate=image`
        );
        this.category = response.data.data;
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    },
    async fetchProductsByCategory() {
      const categoryId = this.$route.params.id;
      try {
        const response = await strapi.get("/products?populate=*");
        this.products = response.data.data
          .map((product) => ({
            ...product,
            categories: product.attributes.categories.data,
          }))
          .filter((product) =>
            product.categories.some((category) => category.id == categoryId)
          );
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    goToUpdateCategory(categoryId) {
      this.$router.push({ name: "UpdateCategory", params: { id: categoryId } });
    },
    getFirstImage(product) {
      const baseURL = "http://localhost:1337"; // Adjust this to your Strapi server URL

      if (
        product.attributes &&
        product.attributes.images &&
        product.attributes.images.data
      ) {
        const images = product.attributes.images.data;

        if (images.length > 0) {
          const firstImage = images[0].attributes;

          if (
            firstImage.formats &&
            firstImage.formats.thumbnail &&
            firstImage.formats.thumbnail.url
          ) {
            return `${baseURL}${firstImage.formats.thumbnail.url}`;
          } else if (firstImage.url) {
            return `${baseURL}${firstImage.url}`;
          }
        }
      }

      return "/default-image.jpg"; // Provide a path to a default image
    },
    async deleteCategory(categoryId) {
      try {
        const response = await axios.delete(
          `http://localhost:1337/api/categories/${categoryId}`
        );
        if (response.status === 200) {
          alert("Catégorie supprimée avec succès !");
          this.$router.push({ name: "CategoryPage" });
        } else {
          throw new Error("Échec de la suppression de la catégorie.");
        }
      } catch (error) {
        console.error("Erreur lors de la suppression de la catégorie :", error);
        alert("Échec de la suppression de la catégorie. Veuillez réessayer.");
      }
    },
  },

  computed: {
    getCategoryName() {
      return this.category && this.category.attributes
        ? this.category.attributes.name
        : "Category Name Unavailable";
    },

    getCategoryDescription() {
      return this.category && this.category.attributes
        ? this.category.attributes.description
        : "Description de la catégorie indisponible";
    },
    getCategoryImage() {
      if (
        this.category &&
        this.category.attributes &&
        this.category.attributes.image &&
        this.category.attributes.image.data
      ) {
        const image = this.category.attributes.image.data.attributes;
        return image.url.startsWith("/")
          ? `http://localhost:1337${image.url}`
          : image.url;
      }
      return null;
    },
    isFeatured() {
      return this.category && this.category.attributes
        ? this.category.attributes.is_featured
        : false;
    },
  },
};
</script>

<style scoped>
/* Add any custom styles for CategoryDetail.vue here */
</style>
