<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h1>Update User</h1>
        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/" class="active" target="_self">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/client" class="active" target="_self">User</a>
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location">Update User</span>
            </li>
          </ol>
        </ul>
        <div class="separator mb-5"></div>
        <form @submit.prevent="updateClient">
          <div class="form-group">
            <label for="username">Username</label>
            <input
              type="text"
              v-model="client.username"
              class="form-control"
              id="username"
              required
            />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              type="email"
              v-model="client.email"
              class="form-control"
              id="email"
              required
            />
          </div>
          <div class="form-group">
            <label for="provider">Provider</label>
            <input
              type="text"
              v-model="client.provider"
              class="form-control"
              id="provider"
            />
          </div>
          <div class="form-group">
            <label for="confirmed">Confirmed</label>
            <select
              v-model="client.confirmed"
              class="form-control"
              id="confirmed"
            >
              <option :value="true">Oui</option>
              <option :value="false">Non</option>
            </select>
          </div>
          <div class="form-group">
            <label for="role">Role</label>
            <select
              v-model="client.role"
              class="form-control"
              id="role"
              required
            >
              <option v-for="role in roles" :key="role.id" :value="role.id">
                {{ role.name }}
              </option>
            </select>
          </div>
          <button type="submit" class="btn btn-primary">Mettre à jour</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import strapi from "@/services/strapi"; // Ensure this points to your Strapi service

export default {
  name: "UpdateClient",
  data() {
    return {
      client: {
        username: "",
        email: "",
        provider: "",
        confirmed: false,
        role: "", // Ensure role is a simple string
      },
      roles: [],
      clientId: this.$route.params.id, // Get client ID from route parameters
    };
  },
  async created() {
    await this.fetchRoles();
    await this.fetchClient();
  },
  methods: {
    async fetchClient() {
      try {
        const response = await strapi.get(`/users/${this.clientId}?populate=*`);
        const clientData = response.data;
        console.log("Fetched client data:", clientData);

        this.client = {
          id: clientData.id,
          username: clientData.username || "",
          email: clientData.email || "",
          provider: clientData.provider || "",
          confirmed: clientData.confirmed || false,
          role: clientData.role ? clientData.role.id : "", // Ensure role is correctly assigned
        };

        console.log("Assigned client data:", this.client); // Log the assigned client data
      } catch (error) {
        console.error("Error fetching User:", error);
      }
    },
    async fetchRoles() {
      try {
        const response = await strapi.get("/users-permissions/roles");
        this.roles = response.data.roles; // Assuming response.data.roles contains the roles list
        console.log("Fetched roles:", this.roles);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    },
    async updateClient() {
      try {
        await strapi.put(`/users/${this.clientId}`, this.client);
        alert("User updated successfully.");
        this.$router.push({ name: "UserList" });
      } catch (error) {
        console.error("Error updating User:", error);
      }
    },
  },
};
</script>
<style scoped>
.container-fluid {
  padding: 20px;
}
.form-group {
  margin-bottom: 15px;
}
</style>
