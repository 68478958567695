<template>
  <nav class="navbar fixed-top">
    <div class="d-flex align-items-center navbar-left">
      <a href="#" class="menu-button d-none d-md-block" @click="toggleSidebar">
        <div class="text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 9 17"
            class="main"
          >
            <rect x="0.48" y="0.5" width="7" height="1"></rect>
            <rect x="0.48" y="7.5" width="7" height="1"></rect>
            <rect x="0.48" y="15.5" width="7" height="1"></rect>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 17"
            class="sub"
          >
            <rect x="1.56" y="0.5" width="16" height="1"></rect>
            <rect x="1.56" y="7.5" width="16" height="1"></rect>
            <rect x="1.56" y="15.5" width="16" height="1"></rect>
          </svg>
        </div>
      </a>
      <a href="#" class="menu-button-mobile d-xs-block d-sm-block d-md-none">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
          <rect x="0.5" y="0.5" width="25" height="1"></rect>
          <rect x="0.5" y="7.5" width="25" height="1"></rect>
          <rect x="0.5" y="15.5" width="25" height="1"></rect>
        </svg>
      </a>
      <div class="search">
        <input
          type="text"
          placeholder="Search"
          class="form-control"
          id="__BVID__1139"
        />
        <span class="search-icon"><i class="simple-icon-magnifier"></i></span>
      </div>
      <div class="d-inline-block">
        <div id="langddm" class="dropdown b-dropdown ml-2 btn-group">
          <button
            id="langddm__BV_toggle_"
            aria-haspopup="menu"
            aria-expanded="false"
            type="button"
            class="btn btn-light btn-sm language-button"
          >
            <span class="name">EN</span>
          </button>
        </div>
      </div>
    </div>
    <a href="/app" class="navbar-logo active">
      <span class="logo d-none d-xs-block"></span>
      <span class="logo-mobile d-block d-xs-none"></span>
    </a>
    <div class="navbar-right">
      <div class="header-icons d-inline-block align-middle">
        <div class="position-relative d-none d-sm-inline-block">
          <div
            class="dropdown b-dropdown btn-group"
            id="__BVID__30"
            :class="{ show: isDropdownOpen }"
          >
            <button
              aria-haspopup="menu"
              :aria-expanded="isDropdownOpen.toString()"
              type="button"
              class="btn dropdown-toggle btn-empty btn-sm header-icon dropdown-toggle-no-caret"
              id="__BVID__30__BV_toggle_"
              @click="toggleDropdown"
              ref="dropdownToggle"
            >
              <i class="simple-icon-grid"></i>
            </button>
            <ul
              v-show="isDropdownOpen"
              role="menu"
              tabindex="-1"
              class="dropdown-menu position-absolute mt-3 iconMenuDropdown dropdown-menu-right show"
              aria-labelledby="__BVID__30__BV_toggle_"
              style="
                position: absolute;
                transform: translate3d(-189px, -24px, 0px);
                top: 0px;
                left: 0px;
                will-change: transform;
              "
              @click.stop
              ref="dropdownMenu"
            >
              <div>
                <a href="/Product" class="icon-menu-item">
                  <i class="iconsminds-server-2 d-block"></i>
                  Product
                </a>
                <a href="/Category" class="icon-menu-item">
                  <i class="simple-icon-menu d-block"></i>
                  Category
                </a>
                <a href="/app/ui/components/charts" class="icon-menu-item">
                  <i class="iconsminds-bar-chart-4 d-block"></i>
                  Charts
                </a>
                <a href="/app/applications/chat" class="icon-menu-item">
                  <i class="iconsminds-speach-bubble d-block"></i>
                  Chat
                </a>
                <a href="/app/applications/survey" class="icon-menu-item">
                  <i class="iconsminds-formula d-block"></i>
                  Survey
                </a>
                <a href="/app/applications/todo" class="icon-menu-item">
                  <i class="iconsminds-check d-block"></i>
                  To-do List
                </a>
              </div>
            </ul>
          </div>
        </div>
        <div class="position-relative d-inline-block">
          <div
            class="dropdown b-dropdown btn-group"
            :class="{ show: isDropdownOpen1 }"
          >
            <button
              aria-haspopup="menu"
              :aria-expanded="isDropdownOpen1.toString()"
              type="button"
              class="btn dropdown-toggle btn-empty btn-sm header-icon notificationButton dropdown-toggle-no-caret"
              @click="toggleDropdown1"
              ref="dropdownToggle1"
            >
              <i class="simple-icon-bell"></i> <span class="count">3</span>
            </button>
            <ul
              v-show="isDropdownOpen1"
              role="menu"
              tabindex="-1"
              class="dropdown-menu12 position-absolute mt-3 notificationDropdown dropdown-menu-right show scrollbar"
              style="
                position: absolute;
                transform: translate3d(-229px, -24px, 0px);
                top: 0px;
                left: 0px;
                will-change: transform;
              "
              @click.stop
              ref="dropdownMenu1"
            >
              <section class="ps-container">
                <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                  <a href="/app/pages/product/details">
                    <img
                      src="@/assets/img/profiles/l-2.jpg"
                      alt="Joisse Kaycee just sent a new comment!"
                      class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle"
                    />
                  </a>
                  <div class="pl-3 pr-2">
                    <a href="/app/pages/product/details">
                      <p class="font-weight-medium mb-1">
                        Joisse Kaycee just sent a new comment!
                      </p>
                      <p class="text-muted mb-0 text-small">
                        09.04.2018 - 12:45
                      </p>
                    </a>
                  </div>
                </div>
                <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                  <a href="/app/pages/product/details">
                    <img
                      src="@/assets/img/notifications/thumb-1.jpg"
                      alt="1 item is out of stock!"
                      class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle"
                    />
                  </a>
                  <div class="pl-3 pr-2">
                    <a href="/app/pages/product/details">
                      <p class="font-weight-medium mb-1">
                        1 item is out of stock!
                      </p>
                      <p class="text-muted mb-0 text-small">
                        09.04.2018 - 12:45
                      </p>
                    </a>
                  </div>
                </div>
                <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                  <a href="/app/pages/product/details">
                    <img
                      src="@/assets/img/notifications/thumb-2.jpg"
                      alt="New order received! It is total $147,20."
                      class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle"
                    />
                  </a>
                  <div class="pl-3 pr-2">
                    <a href="/app/pages/product/details">
                      <p class="font-weight-medium mb-1">
                        New order received! It is total $147,20.
                      </p>
                      <p class="text-muted mb-0 text-small">
                        09.04.2018 - 12:45
                      </p>
                    </a>
                  </div>
                </div>
                <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                  <a href="/app/pages/product/details">
                    <img
                      src="@/assets/img/notifications/thumb-3.jpg"
                      alt="3 items just added to wish list by a user!"
                      class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle"
                    />
                  </a>
                  <div class="pl-3 pr-2">
                    <a href="/app/pages/product/details">
                      <p class="font-weight-medium mb-1">
                        3 items just added to wish list by a user!
                      </p>
                      <p class="text-muted mb-0 text-small">
                        09.04.2018 - 12:45
                      </p>
                    </a>
                  </div>
                </div>
              </section>
            </ul>
          </div>
        </div>
      </div>
      <div class="user d-inline-block">
        <div
          class="dropdown b-dropdown dropdown-menu-right btn-group"
          id="__BVID__1167"
          :class="{ show: isDropdownOpen2 }"
        >
          <button
            aria-haspopup="menu"
            :aria-expanded="isDropdownOpen2.toString()"
            type="button"
            class="btn dropdown-toggle btn-empty p-0 dropdown-toggle-no-caret"
            id="__BVID__1167__BV_toggle_"
            @click="toggleDropdown2"
            ref="dropdownToggle2"
          >
            <span class="name mr-1">{{ username }}</span>
            <span>
              <img alt="User" src="@/assets/photouser.png" />
            </span>
          </button>
          <ul
            v-show="isDropdownOpen2"
            role="menu"
            tabindex="-1"
            aria-labelledby="__BVID__1167__BV_toggle_"
            class="dropdown-menu mt-3 dropdown-menu-right show"
            style="
              position: absolute;
              transform: translate3d(-19px, -24px, 0px);
              top: 0px;
              left: 0px;
              will-change: transform;
            "
            @click.stop
            ref="dropdownMenu2"
          >
            <li role="presentation">
              <a role="menuitem" href="#" target="_self" class="dropdown-item"
                >Account</a
              >
            </li>
            <li role="presentation">
              <a role="menuitem" href="#" target="_self" class="dropdown-item"
                >Features</a
              >
            </li>
            <li role="presentation">
              <a role="menuitem" href="#" target="_self" class="dropdown-item"
                >History</a
              >
            </li>
            <li role="presentation">
              <a role="menuitem" href="#" target="_self" class="dropdown-item"
                >Support</a
              >
            </li>
            <li role="presentation">
              <hr
                role="separator"
                aria-orientation="horizontal"
                class="dropdown-divider"
              />
            </li>
            <li role="presentation">
              <!-- Update the logout link to trigger the logout method -->
              <a
                role="menuitem"
                href="#"
                target="_self"
                class="dropdown-item"
                @click="logout"
                >Logout</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isDropdownOpen: false,
      isDropdownOpen1: false,
      isDropdownOpen2: false,
      isSidebarOpen: false,
      username: "User",
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    toggleSidebar() {
      // Emit an event to toggle the sidebar
      this.$emit("toggle-sidebar");
    },
    toggleDropdown1() {
      this.isDropdownOpen1 = !this.isDropdownOpen1;
    },
    toggleDropdown2() {
      this.isDropdownOpen2 = !this.isDropdownOpen2;
    },
    handleClickOutside(event) {
      if (!this.$refs.dropdownMenu || !this.$refs.dropdownToggle) return;

      if (
        this.isDropdownOpen &&
        !this.$refs.dropdownMenu.contains(event.target) &&
        !this.$refs.dropdownToggle.contains(event.target)
      ) {
        this.isDropdownOpen = false;
      }
    },
    handleClickOutside1(event) {
      if (!this.$refs.dropdownMenu1 || !this.$refs.dropdownToggle1) return;

      if (
        this.isDropdownOpen1 &&
        !this.$refs.dropdownMenu1.contains(event.target) &&
        !this.$refs.dropdownToggle1.contains(event.target)
      ) {
        this.isDropdownOpen1 = false;
      }
    },
    handleClickOutside2(event) {
      if (!this.$refs.dropdownMenu2 || !this.$refs.dropdownToggle2) return;

      if (
        this.isDropdownOpen2 &&
        !this.$refs.dropdownMenu2.contains(event.target) &&
        !this.$refs.dropdownToggle2.contains(event.target)
      ) {
        this.isDropdownOpen2 = false;
      }
    },
    logout() {
      // Clear token or user session data (implement your logout logic here)
      localStorage.removeItem("token"); // Example: Clearing token from localStorage
      localStorage.removeItem("user"); // Example: Clearing user data from localStorage

      // Redirect to login page
      this.$router.push("/login"); // Assuming your login route is "/login"
    },
  },
  mounted() {
    // Retrieve the user's name from localStorage
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.username) {
      this.username = user.username;
    }

    document.addEventListener("click", this.handleClickOutside);
    document.addEventListener("click", this.handleClickOutside1);
    document.addEventListener("click", this.handleClickOutside2);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
    document.removeEventListener("click", this.handleClickOutside1);
    document.removeEventListener("click", this.handleClickOutside2);
  },
};
</script>

<style scoped>
.dropdown-menu12 {
  max-height: auto; /* Adjust the height as needed */
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  position: absolute;
  z-index: 1000;
}

.scrollbar {
  height: 100%;
  overflow-y: auto;
  padding: 0 10px;
}

.scrollbar::-webkit-scrollbar {
  width: 5px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #a3a3a3;
}
</style>
