<template>
  <footer class="page-footer">
    <div class="footer-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-6">
            <p class="mb-0 text-muted">02 rue Mahmoud Ghaznaoui, Menzha</p>
          </div>
          <div class="col-sm-6 d-none d-sm-block">
            <ul class="breadcrumb pt-0 pr-0 float-right">
              <li class="breadcrumb-item mb-0">
                <a href="#" class="btn-link">NEXT Consult</a>
              </li>
              <li class="breadcrumb-item mb-0">
                <a href="#" class="btn-link">Contact@next.tn</a>
              </li>
              <li class="breadcrumb-item mb-0">
                <a href="#" class="btn-link">+216 71 34 30 76</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  components: {},
};
</script>
<style scoped></style>
