<template>
  <div class="container-fuild">
    <div class="row">
      <div class="disable-text-selection col">
        <div class="row">
          <div class="col col-12">
            <h1>Create Brand</h1>
            <ul
              class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/app" class="active" target="_self">Home</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="/brand" class="active" target="_self">Brand</a>
                </li>
                <li class="breadcrumb-item active">
                  <span aria-current="location">Create Brand</span>
                </li>
              </ol>
            </ul>
            <div class="top-right-button-container">
              <button
                @click="goToBrandList"
                type="button"
                class="btn top-right-button btn-primary btn-lg"
              >
                Return to Brand List
              </button>
            </div>
            <div class="separator mb-5"></div>
          </div>
        </div>

        <div class="row">
          <div class="col col-12">
            <div class="card mb-4">
              <div class="card-body">
                <h4 class="card-title">Add Brand</h4>

                <form @submit.prevent="createBrand">
                  <div class="row">
                    <div class="col-sm-6">
                      <fieldset class="form-group">
                        <legend class="bv-no-focus-ring col-form-label pt-0">
                          Brand name
                        </legend>
                        <input
                          type="text"
                          class="form-control"
                          v-model="brand.name"
                          required
                        />
                      </fieldset>
                    </div>
                    <div class="col-sm-6">
                      <fieldset class="form-group">
                        <legend class="col-form-label pt-0">Logo Image</legend>
                        <input
                          type="file"
                          class="form-control"
                          ref="logoInputRef"
                          @change="handleLogoChange"
                          required
                        />
                        <div v-if="brand.logo">
                          <p>Selected Logo:</p>
                          <p>{{ brand.logo.name }}</p>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12">
                      <fieldset class="form-group">
                        <legend class="col-form-label pt-0">
                          Website Link
                        </legend>
                        <input
                          type="text"
                          class="form-control"
                          v-model="brand.link"
                          required
                        />
                      </fieldset>
                    </div>
                  </div>

                  <button type="submit" class="btn mb-1 btn-outline-success">
                    Create Brand
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";

export default {
  name: "CreateBrand",
  data() {
    return {
      brand: {
        name: "",
        logo: null,
        link: "",
      },
    };
  },
  methods: {
    handleLogoChange(event) {
      const file = event.target.files[0];
      this.brand.logo = file;
    },
    async createBrand() {
      try {
        const formData = new FormData();

        // Create a data object to send to Strapi
        const brandData = {
          name: this.brand.name,
          link: this.brand.link,
        };

        // Append the brandData as JSON to formData
        formData.append("data", JSON.stringify(brandData));

        // Check if logo is present before appending
        if (this.brand.logo) {
          formData.append("files.logo", this.brand.logo);
        }

        // Make the API call to create the brand
        const response = await strapi.post("/brands", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Brand created:", response.data);

        // Reset form fields after successful creation
        this.brand.name = "";
        this.brand.logo = null;
        this.brand.link = "";

        // Redirect to brand list page
        this.$router.push({ name: "BrandPage" });
      } catch (error) {
        console.error("Error creating brand:", error);
      }
    },
    goToBrandList() {
      this.$router.push({ name: "BrandPage" });
    },
  },
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
}

div {
  margin-bottom: 1rem;
}
</style>
