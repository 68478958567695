<template>
  <div class="fixed-background"></div>
  <div class="mx-auto my-auto col-md-10 col-12">
    <div class="card auth-card">
      <div class="position-relative image-side">
        <p class="text-white h2">MAGIC IS IN THE DETAILS</p>
        <p class="white mb-0">
          Please use your credentials to login.
          <br />If you are not a member, please
          <router-link to="/user/register" class="white">register</router-link>.
        </p>
      </div>
      <div class="form-side">
        <router-link to="/" class="active">
          <span class="logo-single"></span>
        </router-link>
        <h6 class="mb-4">Login</h6>
        <form>
          <fieldset class="form-group has-float-label mb-4">
            <legend class="bv-no-focus-ring col-form-label pt-0">E-mail</legend>
            <div>
              <input v-model="email" type="text" class="form-control" />
            </div>
          </fieldset>
          <fieldset class="form-group has-float-label mb-4">
            <legend class="bv-no-focus-ring col-form-label pt-0">
              Password
            </legend>
            <div>
              <input v-model="password" type="password" class="form-control" />
            </div>
          </fieldset>
          <div class="d-flex justify-content-between align-items-center">
            <router-link to="/user/forgot-password"
              >Forget password?</router-link
            >

            <router-link
              type="button"
              class="btn btn-primary btn-lg btn-multiple-state btn-shadow"
              to="/"
              >LOGIN</router-link
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";
import { RouterLink } from "vue-router";
export default {
  components: {
    RouterLink,
  },
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      loginSuccess: false,
      loginError: false,
      user: null,
    };
  },
  methods: {
    goToAdd() {
      this.$router.push({ name: "Home" }); // Replace 'CreateProduct' with your actual route name
    },
    async login() {
      this.loading = true;
      this.loginError = false;
      this.loginSuccess = false;

      try {
        const response = await strapi.post("/auth/local", {
          identifier: this.email,
          password: this.password,
        });

        const token = response.data.jwt;
        const user = response.data.user;

        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));

        this.user = user;
        this.loginSuccess = true;

        this.$router.push({ name: "Home" });
      } catch (error) {
        console.error("Login error:", error.response.data);
        this.loginError = true;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      this.user = JSON.parse(storedUser);
    }
  },
};
</script>

<style scoped>
/* Add your scoped styles here */
</style>
