<template>
  <div class="container-fuild">
    <div class="row">
      <div class="disable-text-selection col">
        <div class="row">
          <div class="col col-12">
            <h1>Add Product</h1>
            <ul
              class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/" class="active" target="_self">Home</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="/product" class="active" target="_self">Product</a>
                </li>
                <li class="breadcrumb-item active">
                  <span aria-current="location">Add Product</span>
                </li>
              </ol>
            </ul>
            <div class="top-right-button-container">
              <button
                @click="goToAddProduct"
                type="button"
                class="btn top-right-button btn-primary btn-lg"
              >
                Return to Product Page
              </button>
            </div>
            <div class="separator mb-5"></div>
          </div>
        </div>

        <div class="row">
          <div class="col col-12">
            <div class="card mb-4">
              <div class="card-body">
                <h4 class="card-title">Add New Product</h4>

                <form @submit.prevent="createProduct">
                  <div class="row">
                    <div class="col-sm-6">
                      <fieldset class="form-group">
                        <legend class="bv-no-focus-ring col-form-label pt-0">
                          Name
                        </legend>
                        <input
                          type="text"
                          class="form-control"
                          v-model="product.name"
                          required
                        />
                      </fieldset>
                    </div>
                    <div class="col-sm-6">
                      <fieldset class="form-group">
                        <legend class="col-form-label pt-0">Price</legend>
                        <input
                          type="number"
                          class="form-control"
                          v-model.number="product.price"
                          step="0.01"
                          required
                        />
                      </fieldset>
                    </div>
                    <div class="col-sm-12">
                      <fieldset class="form-group">
                        <legend class="col-form-label pt-0">Description</legend>
                        <textarea
                          id="textarea"
                          placeholder="Enter something..."
                          rows="4"
                          wrap="soft"
                          class="form-control"
                          v-model="product.description"
                          required
                        ></textarea>
                      </fieldset>
                    </div>

                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="category">Category</label>
                        <select
                          id="category"
                          v-model="product.category"
                          class="form-control is-valid"
                          required
                        >
                          <option
                            v-for="category in categories"
                            :key="category.id"
                            :value="category.id"
                          >
                            {{ category.attributes.name }}
                          </option>
                        </select>
                        <div class="invalid-feedback">
                          Please select a category!
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="specification">Specification</label>
                        <select
                          id="specification"
                          v-model="product.specification"
                          class="form-control is-valid"
                          required
                        >
                          <option
                            v-for="specification in specifications"
                            :key="specification.id"
                            :value="specification.id"
                          >
                            {{ specification.attributes.name }}
                          </option>
                        </select>
                        <div class="invalid-feedback">
                          Please select a specification!
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="brand">Brand</label>
                        <select
                          id="brand"
                          v-model="product.brand"
                          class="form-control is-valid"
                          required
                        >
                          <option
                            v-for="brand in brands"
                            :key="brand.id"
                            :value="brand.id"
                          >
                            {{ brand.attributes.name }}
                          </option>
                        </select>
                        <div class="invalid-feedback">
                          Please select a brand!
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <fieldset class="form-group" id="__BVID__4784">
                        <legend
                          tabindex="-1"
                          class="bv-no-focus-ring col-form-label pt-0"
                          id="__BVID__4784__BV_label_"
                        >
                          Present this product
                        </legend>
                        <div>
                          <div
                            role="group"
                            tabindex="-1"
                            class="bv-no-focus-ring"
                            id="__BVID__4785"
                          >
                            <div
                              class="custom-control custom-control-inline custom-checkbox"
                            >
                              <input
                                type="checkbox"
                                class="custom-control-input is-valid"
                                value="beautiful"
                                id="__BVID__4786"
                                name="__BVID__4785"
                                v-model="product.is_featured"
                              />
                              <label
                                class="custom-control-label"
                                for="__BVID__4786"
                                >Is featured</label
                              >
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div class="col-sm-6">
                      <div
                        id="dropzone"
                        class="vue-dropzone dropzone dz-clickable"
                        @click="triggerFileInput"
                      >
                        <div class="dz-default dz-message">
                          <span>Drop files here to download</span>
                        </div>
                      </div>
                      <input
                        type="file"
                        id="fileInput"
                        ref="fileInputRef"
                        @change="handleImageChange"
                        style="display: none"
                        multiple
                      />
                      <div v-if="product.images.length > 0">
                        <p>Selected Files:</p>
                        <ul>
                          <li
                            v-for="(file, index) in product.images"
                            :key="index"
                          >
                            {{ file.name }}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      class="col-sm-6 d-flex justify-content-center align-items-center"
                    >
                      <button type="submit" class="btn-primary-1 btn-lg">
                        Add
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";

export default {
  name: "CreateProduct",
  data() {
    return {
      product: {
        name: "",
        description: "",
        price: "",
        images: [], // Store multiple images in an array
        is_featured: false,
        category: "", // Ensure this is a string or number matching the selected category ID
        specification: "", // Ensure this matches the selected specification ID
        brand: "", // Ensure this matches the selected brand ID
      },
      categories: [],
      specifications: [],
      brands: [],
    };
  },
  async created() {
    try {
      const [categoriesRes, specificationsRes, brandsRes] = await Promise.all([
        strapi.get("/categories"),
        strapi.get("/specifications"),
        strapi.get("/brands"),
      ]);

      this.categories = categoriesRes.data.data;
      this.specifications = specificationsRes.data.data;
      this.brands = brandsRes.data.data;
    } catch (error) {
      console.error("Error fetching related data:", error);
    }
  },
  methods: {
    handleImageChange(event) {
      const files = event.target.files;

      // Append new files to existing product.images array
      for (let i = 0; i < files.length; i++) {
        this.product.images.push(files[i]);
      }
    },
    triggerFileInput() {
      // Trigger click on file input when dropzone area is clicked
      this.$refs.fileInputRef.click();
    },
    async createProduct() {
      try {
        const formData = new FormData();

        // Create a product object excluding files
        const productData = {
          name: this.product.name,
          description: this.product.description,
          price: this.product.price,
          is_featured: this.product.is_featured,
          categories: this.product.category, // Ensure this matches your Strapi backend relationship name
          specifications: this.product.specification, // Ensure this matches your Strapi backend relationship name
          brand: this.product.brand, // Ensure this matches your Strapi backend relationship name for brand
        };

        formData.append("data", JSON.stringify(productData));

        // Append each image file to form data
        for (let i = 0; i < this.product.images.length; i++) {
          formData.append("files.images", this.product.images[i]);
        }

        const response = await strapi.post("/products", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Product created:", response.data);

        // Reset form fields after successful creation
        this.product = {
          name: "",
          description: "",
          price: "",
          images: [],
          is_featured: false,
          category: "",
          specification: "",
          brand: "",
        };

        this.$router.push({ name: "ProductList" });
      } catch (error) {
        console.error("Error creating product:", error);
      }
    },
    goToAddProduct() {
      this.$router.push({ name: "ProductList" });
    },
  },
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
}

div {
  margin-bottom: 1rem;
}
</style>
