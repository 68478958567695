<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-12">
        <h1>Update Category</h1>
        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/app" class="active" target="_self">Accueil</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/app/category" class="active" target="_self">Category</a>
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location">Update Category</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            @click="goToCategoryPage"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Return to Category List
          </button>
        </div>
        <div class="separator mb-5"></div>
      </div>
      <div class="col col-12">
        <div class="card mb-4">
          <div class="card-body">
            <h4 class="card-title">Update Category</h4>
            <form @submit.prevent="updateCategory">
              <fieldset class="form-group">
                <label for="name" class="col-form-label">Name</label>
                <input
                  v-model="category.name"
                  type="text"
                  class="form-control"
                  id="name"
                  required
                />
              </fieldset>
              <fieldset class="form-group">
                <label for="description" class="col-form-label"
                  >Description</label
                >
                <textarea
                  v-model="category.description"
                  class="form-control"
                  id="description"
                  rows="4"
                  required
                ></textarea>
              </fieldset>
              <fieldset class="form-group">
                <label for="image" class="col-form-label"> Image</label>
                <input
                  type="file"
                  id="image"
                  ref="imageInput"
                  accept="image/*"
                  @change="handleImageChange"
                  class="form-control-file"
                />
              </fieldset>
              <button type="submit" class="btn btn-primary">Update</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";

export default {
  name: "UpdateCategory",
  data() {
    return {
      category: {
        categoryId: null,
        name: "",
        description: "",
        image: null,
      },
    };
  },
  async created() {
    console.log("Component created.");
    this.fetchCategoryDetails();
  },
  methods: {
    async fetchCategoryDetails() {
      const categoryId = this.$route.params.id;
      if (!categoryId) {
        console.error("Category ID is undefined in route parameters.");
        return;
      }
      try {
        const response = await strapi.get(`/categories/${categoryId}`);

        // Log the entire response to understand its structure
        console.log("API Response:", response.data);

        // Check if response.data.data.attributes.name and response.data.data.attributes.description exist
        if (
          response.data.data.attributes.name &&
          response.data.data.attributes.description
        ) {
          this.category = {
            categoryId: categoryId,
            name: response.data.data.attributes.name,
            description: response.data.data.attributes.description,
          };

          // Log to verify assignment
          console.log("Category ID:", categoryId);
          console.log("Category Name:", this.category.name);
          console.log("Category Description:", this.category.description);
        } else {
          console.error(
            "Name or description not found in API response:",
            response.data
          );
        }
      } catch (error) {
        console.error("Error fetching category details:", error);
      }
    },
    async updateCategory() {
      if (!this.category.categoryId) {
        console.error("Category ID is undefined.");
        return;
      }

      try {
        const formData = new FormData();
        formData.append(
          "data",
          JSON.stringify({
            name: this.category.name,
            description: this.category.description,
            // Include other fields you need to update
          })
        );

        if (this.category.image) {
          formData.append("files.image", this.category.image);
        }

        const response = await strapi.put(
          `/categories/${this.category.categoryId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Category updated:", response.data);

        // Show success message and update category details
        alert("Catégorie mise à jour avec succès !");
        this.fetchCategoryDetails(); // Fetch updated details
      } catch (error) {
        console.error("Erreur lors de la mise à jour de la catégorie :", error);
      }
    },
    handleImageChange(event) {
      const file = event.target.files[0];
      this.category.image = file;
    },
    goToCategoryPage() {
      this.$router.push({ name: "CategoryPage" });
    },
  },
};
</script>

<style scoped>
/* Add scoped styles here if needed */
</style>
