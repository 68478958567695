<template>
  <div class="container-fluid">
    <div class="row">
      <div class="disable-text-selection col">
        <div class="row">
          <div class="col col-12">
            <h1>{{ brand.name }}</h1>
            <ul
              class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/brands">Brand</router-link>
                </li>
                <li class="breadcrumb-item active">
                  <span>Details</span>
                </li>
              </ol>
            </ul>
            <div class="top-right-button-container">
              <button
                @click="goToBrandList"
                type="button"
                class="btn top-right-button btn-primary btn-lg"
              >
                Return to Brand Page
              </button>
            </div>
            <div class="separator mb-5"></div>
          </div>
        </div>
        <div class="row">
          <div class="mb-4 col-left col-lg-4 col-12">
            <div class="card mb-4 position-relative">
              <div class="position-absolute card-top-buttons">
                <router-link
                  :to="{ name: 'UpdateBrand', params: { id: brand.id } }"
                >
                  <button
                    type="button"
                    class="btn icon-button btn-outline-white"
                  >
                    <i class="simple-icon-pencil"></i>
                  </button>
                </router-link>
              </div>
              <div>
                <img
                  :src="
                    brand.logo
                      ? getImageUrl(brand.logo)
                      : '/assets/img/profiles/1.jpg'
                  "
                  alt="Logo"
                  class="card-img-top"
                />
              </div>
              <div class="card-body">
                <h3 style="color: crimson">Name :</h3>
                <p class="mb-3">{{ brand.name }}</p>
                <h3 style="color: crimson">Link :</h3>
                <p class="mb-3">
                  <a href="#" @click="visitLink" class="brand-link">
                    <i class="simple-icon-link"></i> Visit link
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div class="mb-4 col-right col-lg-8 col-12">
            <h3 style="color: darkgreen">Products :</h3>
            <p></p>
            <div class="row">
              <div
                class="mb-4 col-lg-6 col-xl-4 col-12"
                v-for="(product, index) in products"
                :key="index"
              >
                <div class="card position-relative product-card">
                  <div class="position-relative">
                    <img
                      :src="getImageUrl(product.image)"
                      :alt="product.name"
                      class="card-img-top fixed-size"
                    />
                  </div>
                  <div class="card-body d-flex flex-column justify-content-end">
                    <h6 class="card-subtitle text-muted">{{ product.name }}</h6>
                    <p
                      class="card-text text-muted text-small mb-0 font-weight-light"
                    >
                      {{ product.date }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";

export default {
  name: "BrandDetail",
  data() {
    return {
      brand: {
        name: "",
        logo: "",
        link: "",
      },
      products: [],
    };
  },
  async created() {
    await this.fetchBrandDetails();
    await this.fetchBrandProducts();
  },
  methods: {
    async fetchBrandDetails() {
      const brandId = this.$route.params.id;
      try {
        const response = await strapi.get(`/brands/${brandId}?populate=*`);
        const attributes = response.data.data.attributes;
        this.brand = {
          name: attributes.name,
          logo: attributes.logo?.data?.attributes?.url || "",
          link: attributes.link,
        };
      } catch (error) {
        console.error("Error fetching brand details:", error);
      }
    },
    async fetchBrandProducts() {
      const brandId = this.$route.params.id;
      try {
        const response = await strapi.get(
          `/products?filters[brand][id][$eq]=${brandId}&populate=*`
        );
        this.products = response.data.data.map((product) => ({
          name: product.attributes.name,
          image: product.attributes.images.data[0]?.attributes?.url || "",
          date: new Date(product.attributes.publishedAt).toLocaleDateString(),
        }));
      } catch (error) {
        console.error("Error fetching brand products:", error);
      }
    },
    getImageUrl(imagePath) {
      return `http://localhost:1337${imagePath}`;
    },
    goToBrandList() {
      this.$router.push({ name: "BrandPage" });
    },
    visitLink() {
      window.open(this.brand.link, "_blank"); // Open link in a new tab/window
    },
    goToUpdateBrand(brandId) {
      this.$router.push({ name: "UpdateBrand", params: { id: brandId } }); // Navigate to update brand page
    },
  },
};
</script>

<style scoped>
.fixed-size {
  width: 100%;
  height: 200px; /* Adjust as needed */
  object-fit: cover;
}

.product-card {
  height: 350px; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card .card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.product-card .card-subtitle {
  margin-bottom: 10px; /* Adjust as needed */
}

.product-card .card-text {
  margin-bottom: 0;
}

.brand-link {
  text-decoration: none;
  color: #007bff; /* Bootstrap primary color for links */
}

.brand-link:hover {
  text-decoration: underline;
}
</style>
