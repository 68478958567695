<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-12">
        <h1>Update Product</h1>
        <div class="top-right-button-container">
          <button
            @click="goToProductList"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Return to Product List
          </button>
        </div>
        <div class="separator mb-5"></div>
      </div>
      <div class="col col-12">
        <div class="card mb-4">
          <div class="card-body">
            <h4 class="card-title">Update Product</h4>
            <form @submit.prevent="updateProduct">
              <fieldset class="form-group">
                <label for="name" class="col-form-label">Product Name</label>
                <input
                  v-model="product.name"
                  type="text"
                  class="form-control"
                  id="name"
                  required
                />
              </fieldset>
              <fieldset class="form-group">
                <label for="description" class="col-form-label"
                  >Product Description</label
                >
                <textarea
                  v-model="product.description"
                  class="form-control"
                  id="description"
                  rows="4"
                  required
                ></textarea>
              </fieldset>
              <fieldset class="form-group">
                <label for="price" class="col-form-label">Price</label>
                <input
                  v-model.number="product.price"
                  type="number"
                  class="form-control"
                  id="price"
                  step="0.01"
                  required
                />
              </fieldset>
              <fieldset class="form-group">
                <label for="category" class="col-form-label">Category</label>
                <select
                  v-model="product.category"
                  class="form-control"
                  id="category"
                  required
                >
                  <option
                    v-for="category in categories"
                    :key="category.id"
                    :value="category.id"
                  >
                    {{ category.attributes.name }}
                  </option>
                </select>
              </fieldset>
              <fieldset class="form-group">
                <label for="specification" class="col-form-label"
                  >Specifications</label
                >
                <select
                  v-model="product.specification"
                  class="form-control"
                  id="specification"
                  required
                >
                  <option
                    v-for="specification in specifications"
                    :key="specification.id"
                    :value="specification.id"
                  >
                    {{ specification.attributes.name }}
                  </option>
                </select>
              </fieldset>
              <fieldset class="form-group">
                <label for="brand" class="col-form-label">Brands</label>
                <select
                  v-model="product.brand"
                  class="form-control"
                  id="brand"
                  required
                >
                  <option
                    v-for="brand in brands"
                    :key="brand.id"
                    :value="brand.id"
                  >
                    {{ brand.attributes.name }}
                  </option>
                </select>
              </fieldset>
              <fieldset class="form-group">
                <label for="current-images" class="col-form-label"
                  >Current Images</label
                >
                <div id="current-images">
                  <div
                    v-for="(image, index) in product.currentImages"
                    :key="index"
                    class="current-image"
                  >
                    <img
                      :src="getImageUrl(image.url)"
                      :alt="`Current Image ${index + 1}`"
                      class="image-preview"
                    />
                    <button
                      @click="removeImage(index, image.id)"
                      type="button"
                      class="btn btn-danger btn-sm remove-button"
                    >
                      Remove
                    </button>
                  </div>
                </div>
                <label for="new-images" class="col-form-label"
                  >Add New Images</label
                >
                <input
                  type="file"
                  id="new-images"
                  ref="imageInput"
                  accept="image/*"
                  @change="handleImageChange"
                  class="form-control-file"
                  multiple
                />
                <div v-if="product.newImages.length > 0">
                  <p>Selected New Files:</p>
                  <ul>
                    <li v-for="(file, index) in product.newImages" :key="index">
                      {{ file.name }}
                    </li>
                  </ul>
                </div>
              </fieldset>
              <button type="submit" class="btn btn-primary">
                Update Product
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import strapi from "@/services/strapi";

export default {
  name: "UpdateProduct",
  data() {
    return {
      product: {
        id: null,
        name: "",
        description: "",
        price: 0,
        category: null,
        specification: null,
        brand: "",
        currentImages: [],
        newImages: [],
        imagesToRemove: [],
      },
      categories: [],
      specifications: [],
      brands: [],
    };
  },
  async created() {
    console.log("Component created.");
    await this.fetchProductDetails();
    await this.fetchCategories();
    await this.fetchSpecifications();
    await this.fetchBrands();
  },
  methods: {
    getImageUrl(imagePath) {
      return `http://localhost:1337${imagePath}`;
    },
    handleImageChange(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        this.product.newImages.push({
          file: files[i],
          url: URL.createObjectURL(files[i]),
        });
      }
    },
    async fetchProductDetails() {
      const productId = this.$route.params.id;
      if (!productId) {
        console.error("Product ID is undefined in route parameters.");
        return;
      }
      try {
        const response = await strapi.get(`/products/${productId}?populate=*`);
        if (response.data.data.attributes) {
          const attributes = response.data.data.attributes;
          this.product = {
            id: productId,
            name: attributes.name || "",
            description: attributes.description || "",
            price: attributes.price || 0,
            category: attributes.categories
              ? attributes.categories.data[0]?.id
              : null,
            specification: attributes.specifications
              ? attributes.specifications.data[0]?.id
              : null,
            brand: attributes.brand ? attributes.brand.data.id : "",
            currentImages: attributes.images
              ? attributes.images.data.map((img) => ({
                  id: img.id,
                  url: img.attributes.url,
                }))
              : [],
            newImages: [],
            imagesToRemove: [],
          };
          console.log("Fetched Product:", this.product);
        } else {
          console.error(
            "Product attributes not found in API response:",
            response.data
          );
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    },
    async fetchCategories() {
      try {
        const response = await strapi.get("/categories?populate=*");
        this.categories = response.data.data || [];
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async fetchSpecifications() {
      try {
        const response = await strapi.get("/specifications?populate=*");
        this.specifications = response.data.data || [];
      } catch (error) {
        console.error("Error fetching specifications:", error);
      }
    },
    async fetchBrands() {
      try {
        const response = await strapi.get("/brands?populate=*");
        this.brands = response.data.data || [];
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    },
    async removeImage(index, imageId) {
      try {
        const response = await axios.delete(
          `http://localhost:1337/api/upload/files/${imageId}`
        );
        if (response.status === 200) {
          this.product.currentImages.splice(index, 1);
          console.log("Image deleted:", imageId);
        } else {
          console.error("Failed to delete image.");
        }
      } catch (error) {
        console.error("Error deleting image:", error);
      }
    },
    async updateProduct() {
      if (!this.product.id) {
        console.error("Product ID is undefined.");
        return;
      }

      try {
        const formData = new FormData();
        formData.append(
          "data",
          JSON.stringify({
            name: this.product.name,
            description: this.product.description,
            price: this.product.price,
            categories: this.product.category,
            specifications: this.product.specification,
            brand: this.product.brand,
          })
        );

        this.product.newImages.forEach((image) => {
          formData.append("files.images", image.file);
        });

        const response = await strapi.put(
          `/products/${this.product.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Product updated:", response.data);

        alert("Product successfully updated!");
        this.$router.push({ name: "ProductList" });
        this.fetchProductDetails(); // Fetch updated details
      } catch (error) {
        console.error("Error updating product:", error);
      }
    },

    goToProductList() {
      this.$router.push({ name: "ProductList" });
    },
  },
  computed: {
    currentCategoryName() {
      const selectedCategory = this.categories.find(
        (category) => category.id === this.product.category
      );
      return selectedCategory ? selectedCategory.attributes.name : "None";
    },
    currentSpecificationName() {
      const selectedSpecification = this.specifications.find(
        (spec) => spec.id === this.product.specification
      );
      return selectedSpecification
        ? selectedSpecification.attributes.name
        : "None";
    },
    currentBrandName() {
      const selectedBrand = this.brands.find(
        (brand) => brand.id === this.product.brand
      );
      return selectedBrand ? selectedBrand.attributes.name : "None";
    },
  },
};
</script>

<style scoped>
.current-image {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.image-preview {
  width: 60px;
  height: 57px;
  margin-right: 14px;
}
</style>
