<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-12">
        <h1>Brand List</h1>
        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/" class="active" target="_self">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/brand" class="active" target="_self">Brand</a>
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location">Brand List</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            @click="goToAddBrand"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Add New
          </button>
        </div>
        <div class="separator mb-5"></div>
      </div>
    </div>
    <div class="row">
      <div
        v-for="brand in paginatedBrands"
        :key="brand.id"
        class="mb-3 col col-12"
      >
        <div class="card d-flex flex-row">
          <a
            :href="`/brand/detail/${brand.id}`"
            class="d-flex fixed-size-image"
          >
            <img
              :src="getBrandImage(brand)"
              alt="Brand Logo"
              class="list-thumbnail responsive border-0"
            />
          </a>
          <div class="pl-2 d-flex flex-grow-1 min-width-zero">
            <div
              class="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center"
            >
              <a :href="`/brand/detail/${brand.id}`" class="w-40 w-sm-100">
                <p class="list-item-heading mb-0 truncate">
                  {{ brand.attributes.name }}
                </p>
              </a>
              <div class="btn-group" role="group" aria-label="Brand Actions">
                <button
                  type="button"
                  class="btn btn-outline-info"
                  @click="goToUpdateBrand(brand.id)"
                >
                  Update
                </button>
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  @click="deleteBrand(brand.id)"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-12">
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <button
                class="page-link"
                @click="prevPage"
                :disabled="currentPage === 1"
              >
                Previous
              </button>
            </li>
            <li
              class="page-item"
              v-for="pageNumber in totalPages"
              :key="pageNumber"
              :class="{ active: pageNumber === currentPage }"
            >
              <button class="page-link" @click="goToPage(pageNumber)">
                {{ pageNumber }}
              </button>
            </li>
            <li
              class="page-item"
              :class="{ disabled: currentPage === totalPages }"
            >
              <button
                class="page-link"
                @click="nextPage"
                :disabled="currentPage === totalPages"
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";

export default {
  name: "BrandPage",
  data() {
    return {
      brands: [],
      totalRoutes: 0,
      totalPages: 0,
      routesPerPage: 6,
      currentPage: 1,
    };
  },
  computed: {
    paginatedBrands() {
      const startIndex = (this.currentPage - 1) * this.routesPerPage;
      const endIndex = startIndex + this.routesPerPage;
      return this.brands.slice(startIndex, endIndex);
    },
  },
  async created() {
    await this.fetchBrands();
  },
  methods: {
    getBrandImage(brand) {
      const baseURL = "http://localhost:1337";
      const logo = brand.attributes.logo.data;

      if (logo) {
        const imgAttributes = logo.attributes;
        if (
          imgAttributes.formats &&
          imgAttributes.formats.thumbnail &&
          imgAttributes.formats.thumbnail.url
        ) {
          return `${baseURL}${imgAttributes.formats.thumbnail.url}`;
        } else if (imgAttributes.url) {
          return `${baseURL}${imgAttributes.url}`;
        }
      }
      return "/default-category-image.jpg";
    },
    async fetchBrands() {
      try {
        const response = await strapi.get(`/brands?populate=*`);
        this.brands = response.data.data;

        // Calculate total number of routes and pages
        this.totalRoutes = this.brands.length;
        this.totalPages = Math.ceil(this.totalRoutes / this.routesPerPage);

        // Slice brands to show only current page's data
        const startIndex = (this.currentPage - 1) * this.routesPerPage;
        const endIndex = startIndex + this.routesPerPage;
        this.brands = this.brands.slice(startIndex, endIndex);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchBrands();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchBrands();
      }
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchBrands();
    },
    goToAddBrand() {
      this.$router.push({ name: "CreateBrand" }); // Navigate to create brand page
    },
    goToUpdateBrand(brandId) {
      this.$router.push({ name: "UpdateBrand", params: { id: brandId } }); // Navigate to update brand page
    },
    async deleteBrand(brandId) {
      if (confirm("Are you sure you want to remove this Brand?")) {
        try {
          await strapi.delete(`/brands/${brandId}`);
          alert("Brand successfully removed.");
          this.fetchBrands(); // Refresh brand list after deletion
        } catch (error) {
          console.error("Error removing Brand:", error);
        }
      }
    },
  },
};
</script>

<style scoped>
.list-thumbnail {
  width: 100px; /* Adjust the width as needed */
  height: 94px; /* Adjust the height as needed */
  object-fit: cover; /* Ensures the image covers the entire container */
  object-position: center;
}
.fixed-size-image {
  width: 100px; /* Set your desired width */
  height: 94px; /* Set your desired height */
  overflow: hidden;
}
</style>
