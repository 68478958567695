<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h1>Add a New User</h1>
        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/" class="active" target="_self">Accueil</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/User" class="active" target="_self">Users</a>
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location">Add User</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            @click="goToUsers"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Return to Users page
          </button>
        </div>
        <div class="separator mb-5"></div>
        <div class="row">
          <div class="col col-12">
            <div class="card mb-4">
              <div class="card-body">
                <h4 class="card-title">Add User</h4>
                <form @submit.prevent="addClient">
                  <div class="row">
                    <div class="col-sm-6">
                      <fieldset class="form-group">
                        <legend class="bv-no-focus-ring col-form-label pt-0">
                          Username
                        </legend>
                        <input
                          type="text"
                          v-model="newClient.username"
                          class="form-control"
                          id="username"
                          required
                        />
                      </fieldset>
                    </div>
                    <div class="col-sm-6">
                      <fieldset class="form-group">
                        <legend class="bv-no-focus-ring col-form-label pt-0">
                          Email
                        </legend>
                        <input
                          type="email"
                          v-model="newClient.email"
                          class="form-control"
                          id="email"
                          required
                        />
                      </fieldset>
                    </div>
                    <div class="col-sm-6">
                      <fieldset class="form-group">
                        <legend class="bv-no-focus-ring col-form-label pt-0">
                          Password
                        </legend>
                        <input
                          type="password"
                          v-model="newClient.password"
                          class="form-control"
                          id="password"
                          required
                        />
                      </fieldset>
                    </div>
                    <div class="col-sm-6"></div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="category">Role</label>
                        <select
                          v-model="newClient.role"
                          class="form-control is-valid"
                          id="role"
                          required
                        >
                          <option
                            v-for="role in roles"
                            :key="role.id"
                            :value="role.id"
                          >
                            {{ role.name }}
                          </option>
                        </select>
                        <div class="invalid-feedback">
                          Please select a role!
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6"></div>

                    <div class="col-sm-6">
                      <div class="form-group switch-group">
                        <label for="confirmed">Confirmed</label>
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="newClient.confirmed"
                          />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group switch-group">
                        <label for="blocked">Blocked</label>
                        <label class="switch">
                          <input type="checkbox" v-model="newClient.blocked" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <button type="submit" class="btn btn-primary">
                    Add User
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi"; // Ensure this points to your Strapi service

export default {
  name: "AddClient",
  data() {
    return {
      newClient: {
        username: "",
        email: "",
        password: "",
        provider: "local",
        confirmed: false,
        blocked: false,
        role: "",
      },
      roles: [],
    };
  },
  async created() {
    await this.fetchRoles();
  },
  methods: {
    async fetchRoles() {
      try {
        const response = await strapi.get("/users-permissions/roles");
        this.roles = response.data.roles; // Assuming response.data.roles contains the roles list
        console.log("Fetched roles:", this.roles);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    },
    async addClient() {
      try {
        const response = await strapi.post("/users", this.newClient);
        console.log("New client added:", response.data);
        alert("User added successfully.");
        this.$router.push({ name: "UserList" }); // Navigate back to client list
      } catch (error) {
        console.error("Error adding customer:", error);
      }
    },
    goToUsers() {
      this.$router.push({ name: "UserList" });
    },
  },
};
</script>
<style scoped>
.container-fluid {
  padding: 20px;
}
.form-group {
  margin-bottom: 15px;
}
.switch-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 35px; /* Adjust this value to align switches vertically */
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #2196f3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
</style>
